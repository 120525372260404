import styles from './mIndex.module.scss';

const MCommunityVotePage = () => {
  return (
    <>
      <div className="only500">
        <div className={styles.learn500Child} />
      </div>
      <div className="layout500">
        <div className={styles.communityWrapepr}>
          <div className={styles.communityTitle}>
            Hippocrat
            <br />
            Community Vote
          </div>
          <div className={styles.overviewTitle}>
            OVER<span>VIEW</span>
          </div>
          <div className={styles.overviewText}>
            Since its rebranding in 2023, Hippocrat has been committed to
            revolutionizing healthcare data usage by empowering patients with
            control over their data. Hippocrat has introduced platforms such as
            Hippodoc as an affordable telemedicine service for medical deserts
            around the world. Hippocrat has also formed partnerships with #1 B2B
            solution in Korea for health check called Saluscare and major
            medical institutions such as Saint Mary’s Hospital and Asan Medical
            Institute. Hippocrat currently provides DID and decentralized data
            storage solutions for more than 4 million unique health check up
            recipients, of whom 1.4 million renew their data every year. As
            Saluscare works with over 800 hospitals addressing 11M patients in
            the corporate health check market, Hippocrat expects the number of
            users to grow more rapidly in the next few years. These innovations
            have fostered a patient-centered healthcare data ecosystem built on
            transparency and trust. However, existing technologies and
            blockchain infrastructure pose limitations to the full realization
            of Hippocrat’s vision.
            <br />
            <br />
            According to KHIDI’s Health Industry Brief No.367, the compound
            annual growth rate (CAGR) of Korea's healthcare data industry is
            expected to be between 26.1% and 37.6%. The market size is projected
            to grow from 250M USD (2022) to 2.74B ~ 7.53B USD between 2022 and
            2032. Hippocrat currently holds close to 10% market share of adult
            health check data (Annually, 15M patients receive health checks in
            Korea out of 19.6M relevant population). We are targeting to bring
            this close to a majority market share with Saluscare. Hippocrat will
            also expand outside Korea with upcoming partnerships with global
            healthcare institutions and service providers. In the next few
            years, various verticals of healthcare and wellness related data
            will also be supported such as electronic medical records (EMR)
            data, personal healthcare records (PHR), clinical data, genomic
            data, patient-generated health data (PGHD), and life log data.
            <br />
            <br />
            The main barriers to broader adoption of Hippocrat’s ecosystem are:
            <br />
            <li>
              Reliance on existing mainnet infrastructure, therefore difficulty
              of customization according to the needs of our healthcare partners
            </li>
            <li>
              Difficulty crypto components for non-crypto savvy patients and
              healthcare providers to directly interact with the protocol
            </li>
            <li>
              High gas fee of Ethereum mainnet for HPO token transactions which
              are provided as incentives and rewards to our ecosystem
              participants
            </li>
            <li>
              Lack of decentralized storage compliant with Healthcare data
              regulations
            </li>
            <li>
              Lack of native data processing module that ensures HL7 compliance
            </li>
            <br />
            After extensive research and collaboration with stakeholders, we are
            excited to announce our next big step: building our own mainnet
            dedicated to healthcare data, which will drastically enhance the
            utility of the HPO token and further Hippocrat’s mission of
            decentralizing and democratizing healthcare data.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            OUR <span>PROPOSAL</span>
          </div>
          <div className={styles.overviewText}>
            We propose developing a mainnet that addresses the unique needs of
            the healthcare sector, focusing on privacy, interoperability, and
            low-cost, scalable data storage and transactions. Our blockchain
            will offer tools that enable healthcare data to be shared securely
            and used for innovations in precision medicine, clinical trials,
            drug development and patient care, all while ensuring patient data
            sovereignty.
            <br />
            <br />
            The HPO token, currently used for governance and transactions within
            the Hippocrat ecosystem, will transition to a native token on this
            new mainnet. This will elevate HPO from a dApp utility token to the
            main currency of a mainnet for healthcare data. We’ve already
            engaged our partners, including healthcare providers, pharmaceutical
            companies, insurance companies, data platforms and web 3.0 projects,
            who are excited and ready to participate in this transition.
            <br />
            <br />
            Our goal is to expand the Hippocrat ecosystem into a trusted global
            network for healthcare data exchange, positioning HPO to become the
            unique token dominating the healthcare sector of Web 3.0. We will be
            the first successful RWA project to create digital assets based on
            healthcare data and the first DeSci project to create a mainnet used
            by over millions of users. <br />
            <br />
            We are seeking the community’s support for this new direction
            through a vote on Snapshot. We invite all HPO token holders to
            participate and help shape the future of decentralized healthcare
            data.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            THE NEW BLOCKCHAIN : <span>HIPPO PROTOCOL </span>
          </div>
          <div className={styles.overviewText}>
            Hippocrat’s Layer 1 blockchain Hippo Protocol will be designed to
            address the healthcare sector’s most pressing challenges by
            providing an infrastructure that is secure, scalable, and
            user-centric. Our blockchain’s core principles are:
            <br />
            <br />
            <li>
              Data sovereignty for patients, allowing full control over their
              personal health data, which are currently fragmented and scattered
              in various sources that are siloed from each other due to
              regulations.
            </li>
            <li>
              Interoperability between healthcare providers, researchers, and
              patients, enabling seamless data sharing among stakeholders, while
              not compromising on privacy and security.
            </li>
            <li>
              Low-cost microtransactions to support frequent, secure data
              consents, trades and exchanges in real time.
            </li>
            <li>
              Governance led by the community through decentralized protocols
              and voting mechanisms.
            </li>
            <br />
            Our blockchain will offer following technological solutions needed
            by our partners:
            <br />
            <li>Chain Abstraction</li>
            <li>Gas Fee Abstraction</li>
            <li>
              Decentralized database for healthcare / medical records data,
              which can expand to other types of health related data
            </li>
            <li>
              Decentralized governance of the mainnet, composed of nodes and
              validators run by community members and important ecosystem
              partners
            </li>
            <li>
              Data Privacy achieved through De-Identification of Data and ZK
              proof.
            </li>
            <li>zkFilter for Data Labeling </li>
            <li>Built-in Vector Embedding for future AI application</li>
            <br />
            With over 4M user data ready to be onboarded to our ecosystem and
            much more to join the ecosystem over next years, Hippocrat is
            well-positioned to launch a blockchain tailored to healthcare data
            needs.
            <br />
            <br />
            Hippo Protocol Main Features:
            <br />
            <li>
              Fee-abstraction and chain-abstraction for patients and healthcare
              providers and institutions, so that the users do not need to
              interact directly with the blockchain layer directly to use our
              protocol.
            </li>
            <li>
              Healthcare-specific decentralized storage for secure and scalable
              data ownership and management.
            </li>
            <li>
              Full support for various forms of healthcare data ranging from
              health check data to other real-world data (RWD) such as
              electronic medical records (EMR) data, personal healthcare records
              (PHR), clinical data, genomic data, patient-generated health data
              (PGHD), life log data
            </li>
            <li>
              Embedded Hippo Data Processing Module with following features:
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>
                HL7 (Health Level Seven International) HIPAA (Health Insurance
                Portability and Accountability Act) Data Standard Compliance:
                FHIR (Fast Healthcare Interoperability Resources) Mapping
              </li>
              <li>ZK Filters for data tagging and labeling</li>
              <li>Vector Embedding for future AI application</li>
            </div>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            NEW ECOSYSTEM <span>MECHANICS</span>
          </div>
          <div className={styles.overviewText}>
            What Does This Mean for HPO Holders?
            <br />
            <br />
            Moving HPO to its own mainnet will bring significant benefits to HPO
            holders. No longer just a governance or utility token for a single
            dApp, HPO will now fuel an entire decentralized healthcare data
            ecosystem run on its own mainnet. HPO holders will be the key
            stakeholders in this new mainnet, with opportunities to earn staking
            rewards, participate as nodes, and engage in decentralized
            governance.
            <br />
            <br />
            Additional Benefits of the New Mainnet for HPO:
            <br />
            <br />
            <li>
              Expanding the utility of HPO to all transactions happening in the
              HPO mainnet including all the dApps participating in the
              ecosystem.
            </li>
            <li>
              Facilitating the integration of Hippo Data Protocol to the
              healthcare providers who are conservative towards crypto by
              abstracting chain and gas fee from the user experience. Their
              participation will create bigger value for the ecosystem as a
              whole.
            </li>
            <li>
              Become more flexible regarding rewards and penalty of community
              behaviors.
            </li>
            <li>
              Governance of the ecosystem will be more decentralized as the
              nodes and validators will be run by the community
            </li>
            <br />
            With the introduction of this mainnet, additional tokens will be
            minted annually according to a deflationary half-life schedule to
            fuel the ecosystem’s growth. All of the newly minted token will be
            allocated to the community in forms of dev grants, community rewards
            and node rewards, incentivizing more vigorous participation of all
            stakeholders in the ecosystem.
            <br />
            <br />
            How Will the Token Swap Work?
            <br />
            <br />
            The token swap from old HPO to new mainnet token HP will be 1:1. For
            every HPO you currently hold, you will receive an equivalent amount
            of the new mainnet token HP while the old token is frozen. For the
            tokens held in CEX, if partner CEX will support the migration, the
            swap will be through the CEX platform. For individual wallet
            holders, we will provide a bridge for the migration for one year
            after the mainnet launch. Detailed instructions and migration steps
            will be provided post-vote.
            <br />
            <br />
            Hippocrat and Partner Support
            <br />
            <br />
            Are Our Partners On Board?
            <br />
            <br />
            Yes, our existing partners, including healthcare and Centralized
            exchanges, are enthusiastic about the potential to create bespoke
            healthcare ecosystems powered by Hippocrat’s new blockchain.
            <br />
            <br />
            Why Are They Supporting This?
            <br />
            <br />
            Many organizations want to utilize data they store. By leveraging
            our blockchain, organizations can allow Individuals to manage their
            data and get compensated while adhering to global data regulations.
            <br />
            <br />
            What Changes in Hippocrat?
            <br />
            <br />
            Hippocrat will now have the capacity to handle on-chain healthcare
            data, enabling real-time data exchanges with minimal transaction
            costs. This will enhance drug development, clinical trials,
            precision medicine, and personalized healthcare. Our ecosystem will
            continue to thrive, using the new blockchain for even more efficient
            and secure data sharing.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            REBRANDING <span>REFERENCE</span>
          </div>
          <div className={styles.overviewText}>
            Our transition is not without precedent in the Web 3.0 world.
            Several successful projects have undergone similar transformations,
            demonstrating the potential for growth and expanded impact through
            rebranding and pivoting to Layer 1 solutions. Here are two notable
            examples:
            <br />
            <br />
            1. Vanar Chain (formerly Terra Virtua) : Listed in Binance
            <br />
            <li>
              <a
                href="https://coinmarketcap.com/currencies/vanar/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/vanar/
              </a>{' '}
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>Original Focus: NFT-focused digital collectibles platform</li>
              <li>
                Transition: Rebranded to Vanar Chain and expanded to a full
                Layer 1 blockchain (ETH Fork)
              </li>
              <li>
                Outcome: Successfully leveraged their existing community and
                expertise to create a blockchain tailored for AI and gaming.
              </li>
              <li>
                Market Cap Change: <br />
                <div style={{ marginLeft: 20 }}>
                  TVK Market Cap before Mainnet Announcement in Oct 2023: Est.
                  24M USD VANRY Market Cap: 218M USD FDV (24/10/21) (ATH
                  821.52M){' '}
                </div>
              </li>
            </div>
            <br />
            <br />
            2. Self Chain (formerly Frontier Wallet): Listed in Binance <br />
            <li>
              <a
                href="https://coinmarketcap.com/currencies/self-chain/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/self-chain/
              </a>{' '}
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>
                Original Focus: Multi-chain wallet and DeFi aggregation platform
              </li>
              <li>
                Transition: Evolved into Self Chain, a Layer 1 blockchain
                focused on self-sovereign identity and decentralized
                applications (Cosmos SDK)
              </li>
              <li>
                Outcome: Utilized their experience in wallet infrastructure to
                build a robust, modular intent-centric blockchain ecosystem
              </li>
              <li>
                Market Cap:
                <div style={{ marginLeft: 20 }}>
                  FRONT Market Cap before Mainnet Announcement in Jan 2024: Est.
                  40.5M USD SLF Market Cap: 111.77M USD FDV (24/10/21) (ATH
                  278.71M)
                </div>
              </li>
            </div>
            <br />
            These examples illustrate how projects can successfully transition
            from focused applications to more comprehensive blockchain
            solutions, often bringing their existing strengths and communities
            into a broader ecosystem.
            <br />
            <br />
            We believe that this transition will allow us to better serve our
            community, attract new participants, and contribute more
            significantly to the broader blockchain ecosystem.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            NEXT <span>STEPS</span>
          </div>
          <div className={styles.overviewText}>
            The success of this proposal relies on the support of the Hippocrat
            community. We are opening a vote on Snapshot, where all HPO holders
            can cast their vote through their wallet. CEX wallets are not
            supported by Snapshot. Therefore, you would need to move your token
            to the individual wallets such as Metamask, Trust wallet that
            supports ERC-20 tokens.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            FREQUENTLY ASKED <span>QUESTIONS</span>
          </div>
          <div className={styles.overviewText}>
            <b>1. What is Hippocrat?</b>
            <br />
            <br />
            Hippocrat is a decentralized healthcare data protocol empowering
            patients to own and control their data through blockchain
            technology.
            <br />
            <br />
            <b>
              2. What challenges does Hippocrat aim to solve with its new
              blockchain?
            </b>
            <br />
            <br />
            Challenges include fragmented healthcare data, complex patient
            consent processes, and limited interoperability.
            <br />
            <br />
            <b> 3. What is the goal of the new mainnet? </b>
            <br />
            <br />
            To create a low-cost, scalable blockchain dedicated to healthcare,
            enabling seamless data management and transfers, and real-world data
            integration.
            <br />
            <br />
            <b> 4. How will the proposal affect HPO holders?</b>
            <br />
            <br />
            HPO will evolve into a native token on the new blockchain, bringing
            staking rewards, governance rights, and broader utility in
            decentralized healthcare applications.
            <br />
            <br />
            <b> 5. What is the role of the community? </b>
            <br />
            <br />
            The Hippocrat community will be central in voting for the future of
            the blockchain, with token holders influencing governance through
            their votes.
            <br />
            <br />
            <b> 6. What are the benefits for healthcare organizations?</b>
            <br />
            <br />
            Organizations will benefit from low-cost data transactions, seamless
            patient consent management, and incentives for data sharing.
            <br />
            <br />
            Depending on the outcome of this community vote, there may be an
            additional proposal, so be sure to keep your HPO in your wallet.
            <br />
            <br />
            For more detailed information, please join our Telegram and Discord
            communities or visit our Snapshot page to participate in the vote!
          </div>
        </div>
      </div>
      <div className="only360">
        <div className={styles.learn360Child} />
      </div>
      <div className="layout360">
        <div className={styles.communityWrapepr}>
          <div className={styles.communityTitle}>
            Hippocrat
            <br />
            Community Vote
          </div>
          <div className={styles.overviewTitle}>
            OVER<span>VIEW</span>
          </div>
          <div className={styles.overviewText}>
            Since its rebranding in 2023, Hippocrat has been committed to
            revolutionizing healthcare data usage by empowering patients with
            control over their data. Hippocrat has introduced platforms such as
            Hippodoc as an affordable telemedicine service for medical deserts
            around the world. Hippocrat has also formed partnerships with #1 B2B
            solution in Korea for health check called Saluscare and major
            medical institutions such as Saint Mary’s Hospital and Asan Medical
            Institute. Hippocrat currently provides DID and decentralized data
            storage solutions for more than 4 million unique health check up
            recipients, of whom 1.4 million renew their data every year. As
            Saluscare works with over 800 hospitals addressing 11M patients in
            the corporate health check market, Hippocrat expects the number of
            users to grow more rapidly in the next few years. These innovations
            have fostered a patient-centered healthcare data ecosystem built on
            transparency and trust. However, existing technologies and
            blockchain infrastructure pose limitations to the full realization
            of Hippocrat’s vision.
            <br />
            <br />
            According to KHIDI’s Health Industry Brief No.367, the compound
            annual growth rate (CAGR) of Korea's healthcare data industry is
            expected to be between 26.1% and 37.6%. The market size is projected
            to grow from 250M USD (2022) to 2.74B ~ 7.53B USD between 2022 and
            2032. Hippocrat currently holds close to 10% market share of adult
            health check data (Annually, 15M patients receive health checks in
            Korea out of 19.6M relevant population). We are targeting to bring
            this close to a majority market share with Saluscare. Hippocrat will
            also expand outside Korea with upcoming partnerships with global
            healthcare institutions and service providers. In the next few
            years, various verticals of healthcare and wellness related data
            will also be supported such as electronic medical records (EMR)
            data, personal healthcare records (PHR), clinical data, genomic
            data, patient-generated health data (PGHD), and life log data.
            <br />
            <br />
            The main barriers to broader adoption of Hippocrat’s ecosystem are:
            <br />
            <li>
              Reliance on existing mainnet infrastructure, therefore difficulty
              of customization according to the needs of our healthcare partners
            </li>
            <li>
              Difficulty crypto components for non-crypto savvy patients and
              healthcare providers to directly interact with the protocol
            </li>
            <li>
              High gas fee of Ethereum mainnet for HPO token transactions which
              are provided as incentives and rewards to our ecosystem
              participants
            </li>
            <li>
              Lack of decentralized storage compliant with Healthcare data
              regulations
            </li>
            <li>
              Lack of native data processing module that ensures HL7 compliance
            </li>
            <br />
            After extensive research and collaboration with stakeholders, we are
            excited to announce our next big step: building our own mainnet
            dedicated to healthcare data, which will drastically enhance the
            utility of the HPO token and further Hippocrat’s mission of
            decentralizing and democratizing healthcare data.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            OUR <span>PROPOSAL</span>
          </div>
          <div className={styles.overviewText}>
            We propose developing a mainnet that addresses the unique needs of
            the healthcare sector, focusing on privacy, interoperability, and
            low-cost, scalable data storage and transactions. Our blockchain
            will offer tools that enable healthcare data to be shared securely
            and used for innovations in precision medicine, clinical trials,
            drug development and patient care, all while ensuring patient data
            sovereignty.
            <br />
            <br />
            The HPO token, currently used for governance and transactions within
            the Hippocrat ecosystem, will transition to a native token on this
            new mainnet. This will elevate HPO from a dApp utility token to the
            main currency of a mainnet for healthcare data. We’ve already
            engaged our partners, including healthcare providers, pharmaceutical
            companies, insurance companies, data platforms and web 3.0 projects,
            who are excited and ready to participate in this transition.
            <br />
            <br />
            Our goal is to expand the Hippocrat ecosystem into a trusted global
            network for healthcare data exchange, positioning HPO to become the
            unique token dominating the healthcare sector of Web 3.0. We will be
            the first successful RWA project to create digital assets based on
            healthcare data and the first DeSci project to create a mainnet used
            by over millions of users. <br />
            <br />
            We are seeking the community’s support for this new direction
            through a vote on Snapshot. We invite all HPO token holders to
            participate and help shape the future of decentralized healthcare
            data.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            THE NEW BLOCKCHAIN : <span>HIPPO PROTOCOL </span>
          </div>
          <div className={styles.overviewText}>
            Hippocrat’s Layer 1 blockchain Hippo Protocol will be designed to
            address the healthcare sector’s most pressing challenges by
            providing an infrastructure that is secure, scalable, and
            user-centric. Our blockchain’s core principles are:
            <br />
            <br />
            <li>
              Data sovereignty for patients, allowing full control over their
              personal health data, which are currently fragmented and scattered
              in various sources that are siloed from each other due to
              regulations.
            </li>
            <li>
              Interoperability between healthcare providers, researchers, and
              patients, enabling seamless data sharing among stakeholders, while
              not compromising on privacy and security.
            </li>
            <li>
              Low-cost microtransactions to support frequent, secure data
              consents, trades and exchanges in real time.
            </li>
            <li>
              Governance led by the community through decentralized protocols
              and voting mechanisms.
            </li>
            <br />
            Our blockchain will offer following technological solutions needed
            by our partners:
            <br />
            <li>Chain Abstraction</li>
            <li>Gas Fee Abstraction</li>
            <li>
              Decentralized database for healthcare / medical records data,
              which can expand to other types of health related data
            </li>
            <li>
              Decentralized governance of the mainnet, composed of nodes and
              validators run by community members and important ecosystem
              partners
            </li>
            <li>
              Data Privacy achieved through De-Identification of Data and ZK
              proof.
            </li>
            <li>zkFilter for Data Labeling </li>
            <li>Built-in Vector Embedding for future AI application</li>
            <br />
            With over 4M user data ready to be onboarded to our ecosystem and
            much more to join the ecosystem over next years, Hippocrat is
            well-positioned to launch a blockchain tailored to healthcare data
            needs.
            <br />
            <br />
            Hippo Protocol Main Features:
            <br />
            <li>
              Fee-abstraction and chain-abstraction for patients and healthcare
              providers and institutions, so that the users do not need to
              interact directly with the blockchain layer directly to use our
              protocol.
            </li>
            <li>
              Healthcare-specific decentralized storage for secure and scalable
              data ownership and management.
            </li>
            <li>
              Full support for various forms of healthcare data ranging from
              health check data to other real-world data (RWD) such as
              electronic medical records (EMR) data, personal healthcare records
              (PHR), clinical data, genomic data, patient-generated health data
              (PGHD), life log data
            </li>
            <li>
              Embedded Hippo Data Processing Module with following features:
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>
                HL7 (Health Level Seven International) HIPAA (Health Insurance
                Portability and Accountability Act) Data Standard Compliance:
                FHIR (Fast Healthcare Interoperability Resources) Mapping
              </li>
              <li>ZK Filters for data tagging and labeling</li>
              <li>Vector Embedding for future AI application</li>
            </div>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            NEW ECOSYSTEM <span>MECHANICS</span>
          </div>
          <div className={styles.overviewText}>
            What Does This Mean for HPO Holders?
            <br />
            <br />
            Moving HPO to its own mainnet will bring significant benefits to HPO
            holders. No longer just a governance or utility token for a single
            dApp, HPO will now fuel an entire decentralized healthcare data
            ecosystem run on its own mainnet. HPO holders will be the key
            stakeholders in this new mainnet, with opportunities to earn staking
            rewards, participate as nodes, and engage in decentralized
            governance.
            <br />
            <br />
            Additional Benefits of the New Mainnet for HPO:
            <br />
            <br />
            <li>
              Expanding the utility of HPO to all transactions happening in the
              HPO mainnet including all the dApps participating in the
              ecosystem.
            </li>
            <li>
              Facilitating the integration of Hippo Data Protocol to the
              healthcare providers who are conservative towards crypto by
              abstracting chain and gas fee from the user experience. Their
              participation will create bigger value for the ecosystem as a
              whole.
            </li>
            <li>
              Become more flexible regarding rewards and penalty of community
              behaviors.
            </li>
            <li>
              Governance of the ecosystem will be more decentralized as the
              nodes and validators will be run by the community
            </li>
            <br />
            With the introduction of this mainnet, additional tokens will be
            minted annually according to a deflationary half-life schedule to
            fuel the ecosystem’s growth. All of the newly minted token will be
            allocated to the community in forms of dev grants, community rewards
            and node rewards, incentivizing more vigorous participation of all
            stakeholders in the ecosystem.
            <br />
            <br />
            How Will the Token Swap Work?
            <br />
            <br />
            The token swap from old HPO to new mainnet token HP will be 1:1. For
            every HPO you currently hold, you will receive an equivalent amount
            of the new mainnet token HP while the old token is frozen. For the
            tokens held in CEX, if partner CEX will support the migration, the
            swap will be through the CEX platform. For individual wallet
            holders, we will provide a bridge for the migration for one year
            after the mainnet launch. Detailed instructions and migration steps
            will be provided post-vote.
            <br />
            <br />
            Hippocrat and Partner Support
            <br />
            <br />
            Are Our Partners On Board?
            <br />
            <br />
            Yes, our existing partners, including healthcare and Centralized
            exchanges, are enthusiastic about the potential to create bespoke
            healthcare ecosystems powered by Hippocrat’s new blockchain.
            <br />
            <br />
            Why Are They Supporting This?
            <br />
            <br />
            Many organizations want to utilize data they store. By leveraging
            our blockchain, organizations can allow Individuals to manage their
            data and get compensated while adhering to global data regulations.
            <br />
            <br />
            What Changes in Hippocrat?
            <br />
            <br />
            Hippocrat will now have the capacity to handle on-chain healthcare
            data, enabling real-time data exchanges with minimal transaction
            costs. This will enhance drug development, clinical trials,
            precision medicine, and personalized healthcare. Our ecosystem will
            continue to thrive, using the new blockchain for even more efficient
            and secure data sharing.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            REBRANDING <span>REFERENCE</span>
          </div>
          <div className={styles.overviewText}>
            Our transition is not without precedent in the Web 3.0 world.
            Several successful projects have undergone similar transformations,
            demonstrating the potential for growth and expanded impact through
            rebranding and pivoting to Layer 1 solutions. Here are two notable
            examples:
            <br />
            <br />
            1. Vanar Chain (formerly Terra Virtua) : Listed in Binance
            <br />
            <li>
              <a
                href="https://coinmarketcap.com/currencies/vanar/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/vanar/
              </a>{' '}
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>Original Focus: NFT-focused digital collectibles platform</li>
              <li>
                Transition: Rebranded to Vanar Chain and expanded to a full
                Layer 1 blockchain (ETH Fork)
              </li>
              <li>
                Outcome: Successfully leveraged their existing community and
                expertise to create a blockchain tailored for AI and gaming.
              </li>
              <li>
                Market Cap Change: <br />
                <div style={{ marginLeft: 20 }}>
                  TVK Market Cap before Mainnet Announcement in Oct 2023: Est.
                  24M USD VANRY Market Cap: 218M USD FDV (24/10/21) (ATH
                  821.52M){' '}
                </div>
              </li>
            </div>
            <br />
            <br />
            2. Self Chain (formerly Frontier Wallet): Listed in Binance <br />
            <li>
              <a
                href="https://coinmarketcap.com/currencies/self-chain/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/self-chain/
              </a>{' '}
            </li>
            <div style={{ marginLeft: 40 }}>
              <li>
                Original Focus: Multi-chain wallet and DeFi aggregation platform
              </li>
              <li>
                Transition: Evolved into Self Chain, a Layer 1 blockchain
                focused on self-sovereign identity and decentralized
                applications (Cosmos SDK)
              </li>
              <li>
                Outcome: Utilized their experience in wallet infrastructure to
                build a robust, modular intent-centric blockchain ecosystem
              </li>
              <li>
                Market Cap:
                <div style={{ marginLeft: 20 }}>
                  FRONT Market Cap before Mainnet Announcement in Jan 2024: Est.
                  40.5M USD SLF Market Cap: 111.77M USD FDV (24/10/21) (ATH
                  278.71M)
                </div>
              </li>
            </div>
            <br />
            These examples illustrate how projects can successfully transition
            from focused applications to more comprehensive blockchain
            solutions, often bringing their existing strengths and communities
            into a broader ecosystem.
            <br />
            <br />
            We believe that this transition will allow us to better serve our
            community, attract new participants, and contribute more
            significantly to the broader blockchain ecosystem.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            NEXT <span>STEPS</span>
          </div>
          <div className={styles.overviewText}>
            The success of this proposal relies on the support of the Hippocrat
            community. We are opening a vote on Snapshot, where all HPO holders
            can cast their vote through their wallet. CEX wallets are not
            supported by Snapshot. Therefore, you would need to move your token
            to the individual wallets such as Metamask, Trust wallet that
            supports ERC-20 tokens.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 220 }}>
            FREQUENTLY ASKED <span>QUESTIONS</span>
          </div>
          <div className={styles.overviewText}>
            <b>1. What is Hippocrat?</b>
            <br />
            <br />
            Hippocrat is a decentralized healthcare data protocol empowering
            patients to own and control their data through blockchain
            technology.
            <br />
            <br />
            <b>
              2. What challenges does Hippocrat aim to solve with its new
              blockchain?
            </b>
            <br />
            <br />
            Challenges include fragmented healthcare data, complex patient
            consent processes, and limited interoperability.
            <br />
            <br />
            <b> 3. What is the goal of the new mainnet? </b>
            <br />
            <br />
            To create a low-cost, scalable blockchain dedicated to healthcare,
            enabling seamless data management and transfers, and real-world data
            integration.
            <br />
            <br />
            <b> 4. How will the proposal affect HPO holders?</b>
            <br />
            <br />
            HPO will evolve into a native token on the new blockchain, bringing
            staking rewards, governance rights, and broader utility in
            decentralized healthcare applications.
            <br />
            <br />
            <b> 5. What is the role of the community? </b>
            <br />
            <br />
            The Hippocrat community will be central in voting for the future of
            the blockchain, with token holders influencing governance through
            their votes.
            <br />
            <br />
            <b> 6. What are the benefits for healthcare organizations?</b>
            <br />
            <br />
            Organizations will benefit from low-cost data transactions, seamless
            patient consent management, and incentives for data sharing.
            <br />
            <br />
            Depending on the outcome of this community vote, there may be an
            additional proposal, so be sure to keep your HPO in your wallet.
            <br />
            <br />
            For more detailed information, please join our Telegram and Discord
            communities or visit our Snapshot page to participate in the vote!
          </div>
        </div>
      </div>
    </>
  );
};

export default MCommunityVotePage;
