import { useState, useRef } from 'react';
import styles from './home.module.scss';
import MHomePage from './mIndex';
import LoadingAnimation from '../../components/LoadingAnimation';
import VideoComponent from '../../components/VideoOnReady';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const totalVideos = 4;
  const [loadedVideos, setLoadedVideos] = useState<number[]>([]);
  const playerRefs = useRef<(any | undefined)[]>(new Array(totalVideos));

  const handleVideoLoaded = (videoNumber: number, player: any) => {
    setLoadedVideos(prev => {
      const updatedLoadedVideos = new Set(prev);
      updatedLoadedVideos.add(videoNumber);

      playerRefs.current[videoNumber - 1] = player;

      if (updatedLoadedVideos.size === totalVideos) {
        setIsLoading(false);
        setTimeout(() => {
          playerRefs.current.forEach(p => p?.play());
        }, 700);
      }

      return Array.from(updatedLoadedVideos);
    });
  };

  return (
    <section>
      <section className="layoutContainer">
        <LoadingAnimation isLoading={isLoading} />
        <div className="only1000">
          <div>
            <VideoComponent
              key={1}
              src="/home/home.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={1}
              className="basic-video-img"
            />
          </div>
          <div
            className="basic-text-1000"
            style={{
              marginTop: 'calc(50 * var(--vh) - 200px)',
            }}
          >
            <div className={styles.cooperativeProtocolFor}>
              We are Decentralized
              <br />
              Healthcare Data Protocol
            </div>
            <b className={styles.beyondBoundariesBeyondContainer}>
              <p className={styles.theCoolestForm}>Beyond Boundaries,</p>
              <p className={styles.theCoolestForm}> Beyond Healthcare</p>
            </b>
            <div className={styles.seeWhitepaperWrapper}>
              <a
                className={styles.colorBlack}
                href="https://whitepaper.hippocrat.io/"
                target="_blank"
              >
                <div className={styles.seeWhitepaper}>SEE WHITEPAPER</div>
              </a>
              <a
                className={styles.colorBlack}
                href="https://link3.to/hippocrat"
                target="_blank"
              >
                <div className={styles.seeWhitepaper2}>JOIN COMMUNITY</div>
              </a>
            </div>
          </div>
        </div>
        <div className="layout1000">
          <div className={styles.home1000}>
            <a
              className={styles.colorBlack}
              href="https://github.com/hippocrat-dao"
              target="_blank"
            >
              <div className={styles.openSource1}>OPEN-SOURCE</div>
            </a>
            <a
              className={styles.colorBlack}
              href="https://discord.com/invite/hpo"
              target="_blank"
            >
              <div className={styles.openDiscussion}>OPEN-DISCUSSION</div>
            </a>

            <div className={styles.theCoolestFormContainer}>
              <span className={styles.theCoolestFormContainer1}>
                <p className={styles.theCoolestForm}>DAO governance by the </p>
                <p className={styles.theCoolestForm}>
                  community, for the community
                </p>
              </span>
            </div>
            <b className={styles.humanHealthIs}>
              Human health is not determined by one country, organization, or
              race but requires global collaboration.
            </b>

            <div className={styles.enablingGlobalCooperation}>
              Enabling global cooperation with incentivized and open protocol
            </div>
            <div className={styles.selfSovereigntyIsThe}>
              Self-sovereignty is the key for both privacy and utilization of
              healthcare data
            </div>
            <div className={styles.builtOnThe}>
              Built on the most secure and decentralized blockchain
            </div>
            <div className={styles.home1000Child3}>
              <img
                className="basic-img"
                alt=""
                src="/home/goldFlower.png"
                style={{ pointerEvents: 'none' }}
              />
            </div>
            <div
              className={styles.home1000Child4}
              style={{ pointerEvents: 'none' }}
            >
              <img className="basic-img" alt="" src="/home/app.png" />
            </div>
            <div
              className={styles.home1000Child5}
              style={{ pointerEvents: 'none' }}
            >
              <img className="basic-img" alt="" src="/home/web3.png" />
            </div>
            <div
              className={styles.home1000Child6}
              style={{ pointerEvents: 'none' }}
            >
              <img className="basic-img" alt="" src="/home/doubleInf.png" />
            </div>
            <div
              className={styles.home1000Child7}
              style={{ pointerEvents: 'none' }}
            >
              <img className="basic-img" alt="" src="/home/ball.png" />
            </div>
            <div className={styles.weAreIntroducing}>
              We are introducing our first application, Hippo : a universal
              <br />
              remote medical consultation service that ensures equitable
              <br />
              access to healthcare for everyone, irrespective of background,
              <br />
              location, or language.
            </div>
            <div className={styles.hippocratDevelopsOpenSource}>
              Hippocrat develops open-source and open protocols. Discuss freely,
              <br />
              regardless of race, country, or organization, to promote global
              health
              <br />
              improvement. Your ideas could be the beginning of a world-changing
              impact.
              <br />
              Are you ready to initiate change now?
            </div>
            <div className={styles.anyoneCanAccessContainer}>
              <p
                className={styles.theCoolestForm}
              >{`Universal healthcare for`}</p>
              <p className={styles.theCoolestForm}>
                <i className={styles.anytime}>Anyone, Anywhere, Anytime</i>
              </p>
            </div>
            <div className={styles.hippocratIsTransforming}>
              {`Hippocrat is transforming healthcare by empowering individuals to take control of their health data through blockchain and zero-knowledge proof technologies. Within the HPO ecosystem, personal ownership of health data not only enhances the quality of personalized medical care but also contributes to global health. `}
            </div>
            <div className={styles.solutionForPersonalizedContainer}>
              <span>Solution for personalized </span>
              <i className={styles.anytime}>HealthCare</i>
            </div>
            <div className={styles.cryptocurrencyExchange}>
              <span>{`Cryptocurrency `}</span>
              <i className={styles.anytime}>exchange</i>
            </div>
            <div className={styles.partnershipWrapper}>
              <div className={styles.partnership}>
                <span>PARTNER</span>
                <i className={styles.ch}>SHIP</i>
              </div>
              <div className={styles.partnerImageWrapper}>
                <div className={styles.partnerImageBox}>
                  <a href="https://zk.me/" target="_blank">
                    <img
                      alt="partner_zkme"
                      src="/aboutUs/partner/zkme_container.png"
                    />
                  </a>
                  <a href="https://www.panony.com/" target="_blank">
                    <img
                      alt="partner_panony"
                      src="/aboutUs/partner/pan_container.png"
                    />
                  </a>
                  <a href="https://saluscare.co.kr/" target="_blank">
                    <img
                      alt="partner_saluscare"
                      src="/aboutUs/partner/sac_container.png"
                    />
                  </a>
                  <a href="https://confluxnetwork.org/" target="_blank">
                    <img
                      alt="partner_confluxnetword"
                      src="/aboutUs/partner/conflux_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://solve.care/" target="_blank">
                    <img
                      alt="partner_solve"
                      src="/aboutUs/partner/soc_container.png"
                    />
                  </a>
                  <a href="https://iq.wiki/wiki/hippocrat" target="_blank">
                    <img
                      alt="partner_iq"
                      src="/aboutUs/partner/iq_container.png"
                    />
                  </a>
                  <a href="https://axondao.io/" target="_blank">
                    <img
                      alt="partner_axondao"
                      src="/aboutUs/partner/axondao_container.png"
                    />
                  </a>
                  <a href="https://www.healthdataalliance.org/" target="_blank">
                    <img
                      alt="partner_healthdataalliance"
                      src="/aboutUs/partner/hda_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://prom.io/" target="_blank">
                    <img
                      alt="partner_prom"
                      src="/aboutUs/partner/prom_container.png"
                    />
                  </a>
                  <a href="https://www.privateai.com/" target="_blank">
                    <img
                      alt="partner_privateai"
                      src="/aboutUs/partner/privateal_container.png"
                    />
                  </a>
                  <a href="https://www.cudis.xyz/" target="_blank">
                    <img
                      alt="partner_cudis"
                      src="/aboutUs/partner/cudis_container.png"
                    />
                  </a>
                  <a href="https://data-lake.co/" target="_blank">
                    <img
                      alt="partner_datalake"
                      src="/aboutUs/partner/datalake_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://din.lol/" target="_blank">
                    <img
                      alt="partner_din"
                      src="/aboutUs/partner/din_container.png"
                    />
                  </a>
                  <a href="https://www.rido.io/" target="_blank">
                    <img
                      alt="partner_rido"
                      src="/aboutUs/partner/rido_container.png"
                    />
                  </a>
                  <a href="https://www.lillius.net/" target="_blank">
                    <img
                      alt="partner_lillius"
                      src="/aboutUs/partner/lillius_container.png"
                    />
                  </a>
                  <a>
                    <img
                      alt="partner_seoul"
                      src="/aboutUs/partner/seoul_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="http://www.biosolvix.com/" target="_blank">
                    <img
                      alt="partner_biosolvix"
                      src="/aboutUs/partner/biosolvix_container.png"
                    />
                  </a>
                  <a href="https://www.predictivcare.com/" target="_blank">
                    <img
                      alt="partner_predictiv"
                      src="/aboutUs/partner/predictiv_container.png"
                    />
                  </a>
                  <a href="https://bitdoctor.ai/" target="_blank">
                    <img
                      alt="partner_bitDoctorAI"
                      src="/aboutUs/partner/bitDoctorAI_container.png"
                    />
                  </a>
                  <a>
                    <img
                      alt="partner_yours"
                      src="/aboutUs/partner/yours_container.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.home1000Child8}>
              <img
                className="basic-news-img"
                alt=""
                src="/home/contactImg.png"
              />
            </div>
            <div className={styles.contact}>Contact</div>
            <div className={styles.changingHealthcareTogether}>
              Let’s revolutionize
              <br />
              Healthcare Together
            </div>
            <div className={styles.exchangeContainer}>
              <div className={styles.exchangeImageBox}>
                <a
                  href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
                  target="_blank"
                >
                  <img alt="upbit" src="/home/upbit.png" />
                </a>
                <a
                  href="https://coinone.co.kr/exchange/trade/hpo/krw"
                  target="_blank"
                >
                  <img alt="coinone" src="/home/coinone.png" />
                </a>
                <a
                  href="https://www.gopax.co.kr/exchange/hpo-krw"
                  target="_blank"
                >
                  <img alt="gopax" src="/home/gopax.png" />
                </a>
                <a
                  href="https://lightning.korbit.co.kr/trade/?market=hpo-krw"
                  target="_blank"
                >
                  <img alt="korbit" src="/home/korbit.png" />
                </a>
              </div>
              <div className={styles.exchangeImageBox}>
                <a
                  href="https://www.mexc.com/ko-KR/exchange/HPO_USDT?_from=search_spot_trade"
                  target="_blank"
                >
                  <img alt="mexc" src="/home/mexc.png" />
                </a>
              </div>
            </div>

            <a href="https://discord.com/invite/hpo" target="_blank">
              <div className={styles.openSource}>JOIN COMMUNITY</div>
            </a>
            <a href="mailto:support@hippocrat.io" target="_blank">
              <div className={styles.emailUs}>EMAIL US</div>
            </a>

            <div className={styles.learnMore}>
              <a href="/learn/healthcareData">LEARN MORE</a>
            </div>
            <div className={styles.learnMore1}>
              <a href="/learn/web3">LEARN MORE</a>
            </div>
            <div className={styles.learnMore2}>
              <a href="/protocol">LEARN MORE</a>
            </div>
            <a className={styles.colorBlack} href="/hippodoc">
              <div className={styles.letsCheck}>LEARN MORE</div>
            </a>
            <div className={styles.whatWeFocusContainer}>
              <span>WHAT WE </span>
              <i className={styles.anytime}>FOCUS ON</i>
            </div>
          </div>
        </div>
        <div className="only800">
          <img
            className={styles.icon}
            alt=""
            src="/home/app.png"
            style={{ pointerEvents: 'none' }}
          />
          <div>
            <VideoComponent
              key={2}
              src="/home/home.mp4"
              onReady={(videoNumber, player) =>
                handleVideoLoaded(videoNumber, player)
              }
              videoNumber={2}
              className="basic-video-img"
            />
          </div>
          <div
            className="basic-text-800"
            style={{
              marginTop: 'calc(50 * var(--vh) - 200px)',
            }}
          >
            <div
              className={styles.cooperativeProtocolFor}
              style={{
                fontWeight: 'normal',
                fontFamily: 'var(--font-geomanist-ultra)',
              }}
            >
              We are Decentralized
              <br />
              Healthcare Data Protocol
            </div>
            <b className={styles.beyondBoundariesBeyondContainer}>
              <p className={styles.beyondBoundaries}>Beyond Boundaries,</p>
              <p className={styles.beyondBoundaries}> Beyond Healthcare</p>
            </b>
            <div className={styles.seeWhitepaperWrapper}>
              <a
                className={styles.colorBlack}
                href="https://whitepaper.hippocrat.io/"
                target="_blank"
              >
                <div className={styles.seeWhitepaper}>SEE WHITEPAPER</div>
              </a>
              <a
                className={styles.colorBlack}
                href="https://link3.to/hippocrat"
                target="_blank"
              >
                <div className={styles.seeWhitepaper2}>JOIN COMMUNITY</div>
              </a>
            </div>
          </div>
        </div>
        <div className="layout800">
          <div className={styles.home800}>
            <div className={styles.daoGovernanceByContainer}>
              <span className={styles.daoGovernanceByContainer1}>
                <p
                  className={styles.beyondBoundaries}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  DAO governance by the
                </p>
                <p
                  className={styles.beyondBoundaries}
                  style={{
                    fontWeight: 'normal',
                    fontFamily: 'var(--font-geomanist-ultra)',
                  }}
                >
                  community, for the communty
                </p>
              </span>
            </div>
            <b className={styles.humanHealthIs}>
              Human health is not determined by one country, organization, or
              race but requires global collaboration.
            </b>
            <div
              className={styles.enablingGlobalCooperation}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Enabling global cooperation with incentivized and open protocol
            </div>
            <div
              className={styles.selfSovereigntyIsThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Self-sovereignty is the key for both privacy and utilization of
              healthcare data
            </div>
            <div
              className={styles.builtOnThe}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Built on the most secure and decentralized blockchain
            </div>
            <img
              className={styles.maskGroupIcon}
              alt=""
              src="/home/doubleInf.png"
              style={{ pointerEvents: 'none' }}
            />
            <img
              className={styles.maskGroupIcon1}
              alt=""
              src="/home/ball.png"
              style={{ pointerEvents: 'none' }}
            />
            <img
              className={styles.maskGroupIcon2}
              alt=""
              src="/home/goldFlower.png"
              style={{ pointerEvents: 'none' }}
            />
            <div
              className={styles.weAreIntroducing}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              We are introducing our first application, Hippo : a universal
              remote medical consultation service that ensures equitable access
              to healthcare for everyone, irrespective of background, location,
              or language.
            </div>
            <div
              className={styles.hippocratDevelopsOpenSource}
              style={{ fontFamily: 'var(--font-inter)' }}
            >
              Hippocrat develops open-source and open protocols. Discuss freely,
              regardless of race, country, or organization, to promote global
              health improvement. Your ideas could be the beginning of a
              world-changing impact. Are you ready to initiate change now?
            </div>
            <div className={styles.universalHealthcareForContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Universal healthcare for `}</span>
              <i className={styles.anytime}>Anyone, Anywhere, Anytime</i>
            </div>
            <img
              className={styles.maskGroupIcon3}
              alt=""
              src="/home/web3.png"
              style={{ pointerEvents: 'none' }}
            />
            <div
              className={styles.hippocratIsTransforming}
              style={{ fontFamily: 'var(--font-inter)' }}
            >{`Hippocrat is transforming healthcare by empowering individuals to take control of their health data through blockchain and zero-knowledge proof technologies. Within the HPO ecosystem, personal ownership of health data not only enhances the quality of personalized medical care but also contributes to global health. `}</div>
            <div className={styles.solutionForPersonalizedContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                Solution for personalized{' '}
              </span>
              <i className={styles.anytime}>HealthCare</i>
            </div>
            <div className={styles.whatWeFocusContainer}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >
                WHAT WE{' '}
              </span>
              <i className={styles.anytime}>FOCUS ON</i>
            </div>
            <div className={styles.cryptocurrencyExchange}>
              <span
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'var(--font-geomanist-ultra)',
                }}
              >{`Cryptocurrency `}</span>
              <i className={styles.anytime}>exchange</i>
            </div>
            <div className={styles.home800Child2}>
              <img
                className="basic-news-img"
                alt=""
                src="/home/contactImg.png"
              />
            </div>
            <div className={styles.letsRevolutionizeHealthcareContainer}>
              <span className={styles.daoGovernanceByContainer1}>
                <p className={styles.beyondBoundaries}>Let’s revolutionize</p>
                <p className={styles.beyondBoundaries}>Healthcare Together</p>
              </span>
            </div>
            <div className={styles.exchangeContainer}>
              <div className={styles.exchangeImageBox}>
                <a
                  href="https://upbit.com/exchange?code=CRIX.UPBIT.KRW-HPO"
                  target="_blank"
                >
                  <img alt="upbit" src="/home/upbit.png" />
                </a>
                <a
                  href="https://coinone.co.kr/exchange/trade/hpo/krw"
                  target="_blank"
                >
                  <img alt="coinone" src="/home/coinone.png" />
                </a>
                <a
                  href="https://www.gopax.co.kr/exchange/hpo-krw"
                  target="_blank"
                >
                  <img alt="gopax" src="/home/gopax.png" />
                </a>
                <a
                  href="https://lightning.korbit.co.kr/trade/?market=hpo-krw"
                  target="_blank"
                >
                  <img alt="korbit" src="/home/korbit.png" />
                </a>
              </div>
              <div className={styles.exchangeImageBox}>
                <a
                  href="https://www.mexc.com/ko-KR/exchange/HPO_USDT?_from=search_spot_trade"
                  target="_blank"
                >
                  <img alt="mexc" src="/home/mexc.png" />
                </a>
              </div>
            </div>
            <div className={styles.partnershipWrapper}>
              <div className={styles.partnership}>
                <span className={styles.partnershipTxt}>
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontFamily: 'var(--font-geomanist-ultra)',
                    }}
                  >
                    PARTNER
                  </span>
                  <i
                    style={{
                      fontFamily: 'var(--font-bodoni-72)',
                    }}
                    className={styles.ch}
                  >
                    SHIP
                  </i>
                </span>
              </div>
              <div className={styles.partnerImageWrapper}>
                <div className={styles.partnerImageBox}>
                  <a href="https://zk.me/" target="_blank">
                    <img
                      alt="partner_zkme"
                      src="/aboutUs/partner/zkme_container.png"
                    />
                  </a>
                  <a href="https://www.panony.com/" target="_blank">
                    <img
                      alt="partner_panony"
                      src="/aboutUs/partner/pan_container.png"
                    />
                  </a>
                  <a href="https://saluscare.co.kr/" target="_blank">
                    <img
                      alt="partner_saluscare"
                      src="/aboutUs/partner/sac_container.png"
                    />
                  </a>
                  <a href="https://confluxnetwork.org/" target="_blank">
                    <img
                      alt="partner_confluxnetword"
                      src="/aboutUs/partner/conflux_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://solve.care/" target="_blank">
                    <img
                      alt="partner_solve"
                      src="/aboutUs/partner/soc_container.png"
                    />
                  </a>
                  <a href="https://iq.wiki/wiki/hippocrat" target="_blank">
                    <img
                      alt="partner_iq"
                      src="/aboutUs/partner/iq_container.png"
                    />
                  </a>
                  <a href="https://axondao.io/" target="_blank">
                    <img
                      alt="partner_axondao"
                      src="/aboutUs/partner/axondao_container.png"
                    />
                  </a>
                  <a href="https://www.healthdataalliance.org/" target="_blank">
                    <img
                      alt="partner_healthdataalliance"
                      src="/aboutUs/partner/hda_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://prom.io/" target="_blank">
                    <img
                      alt="partner_prom"
                      src="/aboutUs/partner/prom_container.png"
                    />
                  </a>
                  <a href="https://www.privateai.com/" target="_blank">
                    <img
                      alt="partner_privateai"
                      src="/aboutUs/partner/privateal_container.png"
                    />
                  </a>
                  <a href="https://www.cudis.xyz/" target="_blank">
                    <img
                      alt="partner_cudis"
                      src="/aboutUs/partner/cudis_container.png"
                    />
                  </a>
                  <a href="https://data-lake.co/" target="_blank">
                    <img
                      alt="partner_datalake"
                      src="/aboutUs/partner/datalake_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="https://din.lol/" target="_blank">
                    <img
                      alt="partner_din"
                      src="/aboutUs/partner/din_container.png"
                    />
                  </a>
                  <a href="https://www.rido.io/" target="_blank">
                    <img
                      alt="partner_rido"
                      src="/aboutUs/partner/rido_container.png"
                    />
                  </a>
                  <a href="https://www.lillius.net/" target="_blank">
                    <img
                      alt="partner_lillius"
                      src="/aboutUs/partner/lillius_container.png"
                    />
                  </a>
                  <a>
                    <img
                      alt="partner_seoul"
                      src="/aboutUs/partner/seoul_container.png"
                    />
                  </a>
                </div>
                <div className={styles.partnerImageBox}>
                  <a href="http://www.biosolvix.com/" target="_blank">
                    <img
                      alt="partner_biosolvix"
                      src="/aboutUs/partner/biosolvix_container.png"
                    />
                  </a>
                  <a href="https://www.predictivcare.com/" target="_blank">
                    <img
                      alt="partner_predictiv"
                      src="/aboutUs/partner/predictiv_container.png"
                    />
                  </a>
                  <a href="https://bitdoctor.ai/" target="_blank">
                    <img
                      alt="partner_bitDoctorAI"
                      src="/aboutUs/partner/bitDoctorAI_container.png"
                    />
                  </a>
                  <a>
                    <img
                      alt="partner_yours"
                      src="/aboutUs/partner/yours_container.png"
                    />
                  </a>
                </div>
              </div>
            </div>

            <a href="https://discord.com/invite/hpo" target="_blank">
              <div className={styles.joinCommunity}>JOIN COMMUNITY</div>
            </a>
            <a href="mailto:support@hippocrat.io" target="_blank">
              <div className={styles.emailUs}>EMAIL US</div>
            </a>
            <a
              className={styles.colorBlack}
              href="https://github.com/hippocrat-dao"
              target="_blank"
            >
              <div className={styles.openSource}>OPEN-SOURCE</div>
            </a>
            <a
              className={styles.colorBlack}
              href="https://discord.com/invite/hpo"
              target="_blank"
            >
              <div className={styles.openDiscussion}>OPEN-DISCUSSION</div>
            </a>

            <div className={styles.learnMore}>
              <a href="/learn/healthcareData">LEARN MORE</a>
            </div>
            <div className={styles.learnMore1}>
              <a href="/learn/web3">LEARN MORE</a>
            </div>
            <div className={styles.learnMore2}>
              <a href="/protocol">LEARN MORE</a>
            </div>

            <a className={styles.colorBlack} href="/hippodoc">
              <div className={styles.learnMore3}>LEARN MORE</div>
            </a>

            <div className={styles.contact}>Contact</div>
          </div>
        </div>
        <MHomePage handleVideoLoaded={handleVideoLoaded} />
      </section>
    </section>
  );
};

export default HomePage;
