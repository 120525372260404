import styles from './footerNav.module.scss';

const FooterNav = () => {
  return (
    <>
      <footer className={styles.footerWrap}>
        <div className={styles.footer}>
          <div className={styles.footerChild} />
          <a href="/aboutUs">
            <div className={styles.aboutUs}>About Us</div>
          </a>
          <a href="/learn/web3">
            <div className={styles.learn}>Learn</div>
          </a>
          <a href="/hippocratCommunityVote">
            <div className={styles.hippocratCommunityVote}>Community Vote</div>
          </a>
          <a href="/mainnetNode">
            <div className={styles.mainnetNode}>Mainnet Node</div>
          </a>
          <a href="/roadMap">
            <div className={styles.roadmap}>Roadmap</div>
          </a>
          <a href="/whitePaper">
            <div className={styles.whitepaper}>Whitepaper</div>
          </a>
          <a href="/blog">
            <div className={styles.blog}>Blog</div>
          </a>
          <a href="/news">
            <div className={styles.news}>News</div>
          </a>
          <a href="/faq">
            <div className={styles.faqs}>FAQ</div>
          </a>
          <a href="/pressKit">
            <div className={styles.pressKit}>Press Kit</div>
          </a>
          <div className={styles.hippocratDaoFoundation}>
            ⓒ 2023 Hippocrat DAO Foundation. All rights reserved.
          </div>
          <div className={styles.resources}>Resources</div>
          <a href="/hippodoc">
            <div className={styles.hippo}>HIPPODOC</div>
          </a>
          <a href="https://t.me/hippocrat_official" target="_blank">
            <div className={styles.telegram}>Telegram</div>
          </a>
          <a href="https://discord.com/invite/hpo" target="_blank">
            <div className={styles.discord}>Discord</div>
          </a>
          <a
            href="https://community.hippocrat.io/c/announcements"
            target="_blank"
          >
            <div className={styles.circle}>Circle</div>
          </a>
          <a href="https://medium.com/hippocrat" target="_blank">
            <div className={styles.medium}>Medium</div>
          </a>
          <a href="https://www.instagram.com/med_tip.hippo/" target="_blank">
            <div className={styles.instagram}>Instagram</div>
          </a>
          <a href="https://twitter.com/hippocrat_io" target="_blank">
            <div className={styles.twitterX}>Twitter / X</div>
          </a>
          <a
            href="https://www.linkedin.com/company/hippocratlabs/about/"
            target="_blank"
          >
            <div className={styles.linkedIn}>Linked in</div>
          </a>
          <a href="https://github.com/hippocrat-dao" target="_blank">
            <div className={styles.github}>GitHub</div>
          </a>
          <a href="/p2p">
            <div className={styles.p2p}>P2P</div>
          </a>
          <a href="/sdk">
            <div className={styles.sdk}>SDK</div>
          </a>
          <div className={styles.solutions}>Solutions</div>
          <div className={styles.community}>Community</div>
          <a href="/protocol">
            <div className={styles.protocol}>Protocol</div>
          </a>
          <a href="/dao">
            <div className={styles.dao}>DAO</div>
          </a>
          <a href="/hpoToken">
            <div className={styles.hpoToken}>HPO Token</div>
          </a>
          <div className={styles.protocol1}>Ecosystem</div>
          <a href="/">
            <img className={styles.icon} alt="" src="/footer/11111-2@2x.png" />
          </a>
          <div className={styles.footerItem} />
          <img className={styles.icon1} alt="" src="/footer/--2@2x.png" />
          <img className={styles.icon2} alt="" src="/footer/--1@2x.png" />
        </div>
      </footer>
    </>
  );
};

export default FooterNav;
