import styles from './community.module.scss';
import MMainnetNodePage from './mIndex';

const MainnetNodePage = () => {
  return (
    <section
      className="layoutContainer"
      style={{ backgroundColor: '#000', marginBottom: '-20px' }}
    >
      <div className="only1000">
        <div className={styles.learn01Child} />
      </div>
      <div className="layout1000">
        <div className={styles.communityWrapepr}>
          <div className={styles.communityTitle}>
            Mainnet Node
            <br />& Validator Proposal
          </div>
          <div className={styles.communityDesc}>
            Dear Hippocrat Community,
            <br />
            We are excited to present a pivotal moment in Hippocrat's journey.
            As the community vote for the mainnet has been passed with a
            landslide vote for yes, we are opening a new chapter for Hippocrat
            as Hippo Protocol. As we continue to grow and evolve, we have
            identified a strategic opportunity to significantly enhance our
            project's potential and value proposition. This proposal outlines
            our plan to transition Hippocrat from a data infrastructure to a
            full-fledged mainnet project Hippo Protocol, accompanied by a
            carefully considered node and validator selection plan to ensure
            network security and decentralization. This is an important part of
            our carefully considered mainnet token minting strategy to fuel our
            expansion.
          </div>
          <div className={styles.overviewTitle}>
            PROPOSAL <span>OVERVIEW</span>
          </div>
          <div className={styles.overviewText}>
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Transition to Hippo Protocol Mainnet: Elevate Hippocrat from a
                data infrastructure to a mainnet project Hippo Protocol,
                unlocking new possibilities for scalability, interoperability,
                and innovation.
              </li>
              <li>
                Ecosystem Expansion with Node and Validator Partners: Forge
                strategic partnerships to accelerate growth and adoption of the
                Hippo Protocol. Implement a robust node and validator network to
                support the Hippo Protocol.
              </li>
              <li>
                Node and Validator Rewards and Minting Strategy: Introduce a
                token allocation strategy to incentivize network participants
                and ensure long-term sustainability.
              </li>
            </ol>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            NODE AND VALIDATOR SELECTION <span>PLAN</span>
          </div>
          <div className={styles.overviewText}>
            To support our mainnet transition, we propose implementing a
            comprehensive node and validator structure:
            <br />
            <br />
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Node Network: Establish a distributed network of nodes run by
                healthcare institutions and financial institutions partners to
                maintain the Hippo Protocol mainnet.
              </li>
              <li>
                Validator Selection: Implement a rigorous process to select
                trusted validators who will secure the network.
              </li>
              <li>
                Reward Allocation: Introduce a fair and sustainable reward
                system for network participants.
              </li>
            </ol>
            <br />
            <br />
            Hippo Protocol will be a blockchain run by nodes and validators.
            Hippo Protocol foundation distributes rights to operate Hippo nodes
            to ecosystem partners consisting of medical institutions, healthcare
            service providers and financial institutions. Priority whitelist
            will be given to heavy contributors to the Hippocrat community and
            other parties that will contribute to the success of the network. By
            participating as the nodes, the ecosystem partners are contributing
            to the decentralized operation of the network.
            <br />
            <br />
            Hippo Protocol will distribute 30,000 node rights out of 50,000 to
            its ecosystem partners in the first year of the mainnet launch. This
            will be implemented through Hippocrat Node Dashboard. The rest will
            be distributed according to community vote.
            <br />
            <br />
            <div style={{ fontSize: 24, fontWeight: 900 }}>
              Definition of Nodes and Validators in Hippo Protocol:
            </div>
            <br />
            To clarify what nodes and validators are, we have outlined what
            roles they will play in Hippo Protocol ecosystem
            <br />
            <br />
            Nodes:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Nodes are machines that run the Hippo Protocol blockchain
                software and connect to the network in order to maintain
                integrity of Hippo Healthcare Data Protocol. In Hippo Protocol,
                hospitals, clinics and healthcare providers will run the nodes
                to validate the data they are onboarding to the network.
              </li>
              <li>
                They maintain a copy of the blockchain and help propagate
                transactions and blocks across the network. All the transactions
                concerning consent details and data transfers / trades will be
                recorded on chain.
              </li>
              <li>
                All node operators need to purchase Node rights from Hippo
                Protocol.
              </li>
              <li>
                All validators are nodes, but not all nodes are validators.
              </li>
            </ul>
            <br />
            Validators:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Validators are special nodes that participate in the consensus
                process to create and approve new blocks.
              </li>
              <li>
                They stake (lock up) tokens as collateral, which can be slashed
                if they misbehave.
              </li>
              <li>
                Validators earn rewards for their role in securing the network
                and processing transactions.
              </li>
              <li>
                They have voting power proportional to their stake and
                participate in on-chain governance.
              </li>
              <li>
                Only the node operators with over 100 Hippocrat nodes can
                volunteer to become validators. New validators will be decided
                by votes from existing validators.
              </li>
            </ul>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            TOKEN ALLOCATION
            <br />
            FOR HIPPO PROTOCOL <span>PARTICIPATION</span>
          </div>
          <div className={styles.overviewText}>
            To incentivize network participation and ensure long-term
            sustainability, we propose the following token allocation strategy
            for the mainnet:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                80% of inflation will be allocated as rewards for network
                participants.
              </li>
              <li style={{ marginLeft: 20 }}>
                90% of these rewards will go to nodes.
              </li>
              <li style={{ marginLeft: 20 }}>
                10% of these rewards will go to validators.
              </li>
              <li>
                10% will be allocated to ecosystem growth and liquidity provider
                rewards
              </li>
              <li>
                5% will be allocated as the dev grant for dev teams contributing
                to the tech stacks of Hippo Protocol mainnet. The grants will be
                proposed and voted by the community.
              </li>
              <li>
                5% will be allocated to airdrop and marketing for the community
                expansion. Airdrop plans will also be proposed and voted by the
                community.
              </li>
            </ul>
            This allocation strategy aims to:
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>Encourage widespread participation in running nodes.</li>
              <li>
                Ensure adequate compensation for validators' critical role in
                network security.
              </li>
              <li>Promote network decentralization and resilience.</li>
            </ul>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            BENEFITS TO THE <span>COMMUNITY</span>
          </div>
          <div className={styles.overviewText}>
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Enhanced Security: A robust node and validator network will
                strengthen the security and reliability of the Hippocrat
                blockchain.
              </li>
              <li>
                Trustless Data Validation: As healthcare providers and medical
                institutions are participating as nodes, the data onboarded to
                the protocol will be validated by both the data source and the
                data owner without a need for 3rd party verification.
              </li>
              <li>
                Decentralization: Encouraging widespread participation in
                running nodes will promote a more decentralized network.
              </li>
              <li>
                Long-term Sustainability: The reward structure ensures ongoing
                incentives for network maintenance and security.
              </li>
              <li>
                Increased Utility: The expanded ecosystem will provide more use
                cases and applications for Hippocrat tokens, potentially
                increasing their value and demand.
              </li>
            </ul>
            <br />
            We believe these steps are crucial for Hippocrat's evolution and
            will create substantial value for all stakeholders. In the following
            sections, we'll delve into the details of each aspect of this
            proposal, including the precise number of tokens to be minted.
            <br />
            <br />
            Your input is vital to this process. We encourage all token holders
            to review the proposal carefully and participate in the vote.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            MINTING <span>STRATEGY</span>
          </div>
          <div className={styles.overviewText}>
            All of the existing HPO will be exchanged 1:1 with the HP token,
            before any inflation happens. Following is the inflation plan of HP
            every year after the mainnet transition.
            <br />
          </div>
          <div className={styles.overviewText}>
            <div className={styles.mintingTable}>
              <div className={styles.mintingHeader}>
                <div className={styles.mintingHeaderTitle}></div>
                <div className={styles.mintingHeaderTitle}>Year1</div>
                <div className={styles.mintingHeaderTitle}>Year2</div>
                <div className={styles.mintingHeaderTitle}>Year3</div>
                <div className={styles.mintingHeaderTitle}>Year4</div>
                <div className={styles.mintingHeaderTitle}>Year5</div>
                <div className={styles.mintingHeaderTitle}>Year6</div>
                <div className={styles.mintingHeaderTitle}>Every 2 yrs</div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>Inflation</div>
                <div className={styles.mintingBodyTitle}>25%</div>
                <div className={styles.mintingBodyTitle}>20%</div>
                <div className={styles.mintingBodyTitle}>8.33%</div>
                <div className={styles.mintingBodyTitle}>7.69%</div>
                <div className={styles.mintingBodyTitle}>3.57%</div>
                <div className={styles.mintingBodyTitle}>3.45%</div>
                <div className={styles.mintingBodyTitle}>Half-life</div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>
                  Increased
                  <br />
                  Supply
                </div>
                <div className={styles.mintingBodyTitle}>271,183,568</div>
                <div className={styles.mintingBodyTitle}>271,183,568</div>
                <div className={styles.mintingBodyTitle}>135,591,784</div>
                <div className={styles.mintingBodyTitle}>135,591,784</div>
                <div className={styles.mintingBodyTitle}>67,795,892</div>
                <div className={styles.mintingBodyTitle}>67,795,892</div>
                <div className={styles.mintingBodyTitle}>
                  Inflation
                  <br />
                  decreases
                  <br />
                  by half
                </div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>Total supply</div>
                <div className={styles.mintingBodyTitle}>1,355,917,841</div>
                <div className={styles.mintingBodyTitle}>1,627,101,410</div>
                <div className={styles.mintingBodyTitle}>1,762,693,194</div>
                <div className={styles.mintingBodyTitle}>1,898,284,978</div>
                <div className={styles.mintingBodyTitle}>1,966,080,870</div>
                <div className={styles.mintingBodyTitle}>2,033,876,762</div>
                <div className={styles.mintingBodyTitle}>-</div>
              </div>
            </div>
          </div>
          <div className={styles.overviewText}>
            First 4 years accelerated inflation, decelerated inflation for the
            years after, and deceleration continues as the half-life comes every
            two years. Token is designed to be deflationary.{' '}
            <strong>
              The initial years have accelerated inflation to encourage the
              participation of ecosystem partners and community members.
              However, once we onboard millions of users through our partners as
              planned, this will generate enough on-chain transactions and
              activities to offset the inflation, making the overall token
              supply deflationary through increased utility. As more people
              utilize the protocol and more gas fee is used, the net token
              circulating supply will decrease with time. This will make the
              token actually deflationary because more tokens will come back to
              the ecosystem vault than minted.
            </strong>{' '}
            The inflation will begin after the launch of Hippo Protocol mainnet.
            Migration of existing HPO to HP will be open for one year after the
            mainnet launch.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            REFERENCE MINTING STRATEGIES:
            <br />
            REBRANDED <span>PROJECTS</span>
          </div>
          <div className={styles.overviewText}>
            Our transition is not without precedent in the Web 3.0 world.
            Several successful projects have undergone similar transformations,
            demonstrating the potential for growth and expanded impact through
            rebranding and pivoting to Layer 1 solutions. Here are two notable
            examples:
            <br />
            <br />
            1. Vanar Chain (formerly Terra Virtua) : Listed in Binance &
            Coinbase
            <br />
            <div style={{ marginLeft: 15 }}>
              <a
                href="https://coinmarketcap.com/currencies/vanar/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/vanar/
              </a>{' '}
            </div>
            <div style={{ marginLeft: 25 }}>
              <li>100% Increase in Total Supply (Total Supply became 2X)</li>
              <li>1:1 Swap between TVK and VANRY</li>
              <li>New Supply was allocated as</li>
              <ol type="i" style={{ paddingLeft: 50, margin: 0 }}>
                <li>83% to validator rewards</li>
                <li>13% for development rewards</li>
                <li>4% for airdrops and other community incentives</li>
              </ol>
            </div>
            <br />
            <br />
            2. Self Chain (formerly Frontier Wallet): Listed in Binance
            <br />
            <div style={{ marginLeft: 15 }}>
              <a
                href="https://coinmarketcap.com/currencies/self-chain/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/self-chain/
              </a>{' '}
            </div>
            <div style={{ marginLeft: 25 }}>
              <li>300% Increase in Total Supply (Total Supply became 4X)</li>
              <li>1:1 Swap between FRONT and SLF</li>
              <li>Allocation for SLF was</li>
              <ol type="i" style={{ paddingLeft: 50, margin: 0 }}>
                <li>25% airdrop to existing holder</li>
                <li>10% to equity investors</li>
                <li>28% to validator nodes / growth sale</li>
                <li>19% to ecosystem</li>
                <li>10% to foundation nodes</li>
                <li>8% to team</li>
              </ol>
            </div>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            FREQUENTLY ASKED <span>QUESTIONS</span>
          </div>
          <div className={styles.overviewText}>
            To provide further clarity on our proposal, we've addressed some
            common questions below:
            <br />
            <br />
            Q: Will the node distribution amounts be made public?
            <br />
            A: To respect the privacy of our partners, we won't disclose
            specific distribution amounts. However, we will publish a list of
            investors to maintain transparency.
            <br />
            <br />
            Q: Doesn't having a fixed number of nodes limit decentralization?
            <br />
            A: We understand this concern. While we're minting a total of 50,000
            nodes, we're starting with an initial distribution of 30,000. The
            remaining 20,000 will be distributed as needed, based on future
            community voting. This approach allows us to grow and adapt to
            network needs while maintaining a high degree of decentralization.
            <br />
            <br />
            Q: Who will act as validators?
            <br />
            A: During the initial migration phase, the foundation and key
            investors will operate as validators to ensure a smooth transition.
            After this phase, validators will be selected through a community
            voting process from among node operators holding more than 100
            nodes. This transition ensures both initial stability and long-term
            community governance.
            <br />
            <br />
            Q: How will the token migration process work?
            <br />
            A: We're providing two main methods for a smooth migration:
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>Automatic distribution through participating exchanges</li>
              <li>
                A proprietary bridge that will operate for one year, allowing
                for direct transfers
              </li>
            </ol>
            <br />
            Q: How will the initial 30,000 nodes be distributed? <br />
            A: The initial nodes will be distributed using our node dashboard.
            Whitelisted partners can operate them directly.
            <br />
            <br />
            We hope these answers provide additional clarity. If you have any
            further questions, please don't hesitate to ask in our community
            channels.
          </div>
        </div>
      </div>
      <div className="only800">
        <div className={styles.learn800Child} />
      </div>
      <div className="layout800">
        <div className={styles.communityWrapepr}>
          <div className={styles.communityTitle}>
            Mainnet Node
            <br />& Validator Proposal
          </div>
          <div className={styles.communityDesc}>
            Dear Hippocrat Community,
            <br />
            We are excited to present a pivotal moment in Hippocrat's journey.
            As the community vote for the mainnet has been passed with a
            landslide vote for yes, we are opening a new chapter for Hippocrat
            as Hippo Protocol. As we continue to grow and evolve, we have
            identified a strategic opportunity to significantly enhance our
            project's potential and value proposition. This proposal outlines
            our plan to transition Hippocrat from a data infrastructure to a
            full-fledged mainnet project Hippo Protocol, accompanied by a
            carefully considered node and validator selection plan to ensure
            network security and decentralization. This is an important part of
            our carefully considered mainnet token minting strategy to fuel our
            expansion.
          </div>
          <div className={styles.overviewTitle}>
            PROPOSAL <span>OVERVIEW</span>
          </div>
          <div className={styles.overviewText}>
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Transition to Hippo Protocol Mainnet: Elevate Hippocrat from a
                data infrastructure to a mainnet project Hippo Protocol,
                unlocking new possibilities for scalability, interoperability,
                and innovation.
              </li>
              <li>
                Ecosystem Expansion with Node and Validator Partners: Forge
                strategic partnerships to accelerate growth and adoption of the
                Hippo Protocol. Implement a robust node and validator network to
                support the Hippo Protocol.
              </li>
              <li>
                Node and Validator Rewards and Minting Strategy: Introduce a
                token allocation strategy to incentivize network participants
                and ensure long-term sustainability.
              </li>
            </ol>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            NODE AND VALIDATOR SELECTION <span>PLAN</span>
          </div>
          <div className={styles.overviewText}>
            To support our mainnet transition, we propose implementing a
            comprehensive node and validator structure:
            <br />
            <br />
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Node Network: Establish a distributed network of nodes run by
                healthcare institutions and financial institutions partners to
                maintain the Hippo Protocol mainnet.
              </li>
              <li>
                Validator Selection: Implement a rigorous process to select
                trusted validators who will secure the network.
              </li>
              <li>
                Reward Allocation: Introduce a fair and sustainable reward
                system for network participants.
              </li>
            </ol>
            <br />
            <br />
            Hippo Protocol will be a blockchain run by nodes and validators.
            Hippo Protocol foundation distributes rights to operate Hippo nodes
            to ecosystem partners consisting of medical institutions, healthcare
            service providers and financial institutions. Priority whitelist
            will be given to heavy contributors to the Hippocrat community and
            other parties that will contribute to the success of the network. By
            participating as the nodes, the ecosystem partners are contributing
            to the decentralized operation of the network.
            <br />
            <br />
            Hippo Protocol will distribute 30,000 node rights out of 50,000 to
            its ecosystem partners in the first year of the mainnet launch. This
            will be implemented through Hippocrat Node Dashboard. The rest will
            be distributed according to community vote.
            <br />
            <br />
            <div style={{ fontSize: 24, fontWeight: 900 }}>
              Definition of Nodes and Validators in Hippo Protocol:
            </div>
            <br />
            To clarify what nodes and validators are, we have outlined what
            roles they will play in Hippo Protocol ecosystem
            <br />
            <br />
            Nodes:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Nodes are machines that run the Hippo Protocol blockchain
                software and connect to the network in order to maintain
                integrity of Hippo Healthcare Data Protocol. In Hippo Protocol,
                hospitals, clinics and healthcare providers will run the nodes
                to validate the data they are onboarding to the network.
              </li>
              <li>
                They maintain a copy of the blockchain and help propagate
                transactions and blocks across the network. All the transactions
                concerning consent details and data transfers / trades will be
                recorded on chain.
              </li>
              <li>
                All node operators need to purchase Node rights from Hippo
                Protocol.
              </li>
              <li>
                All validators are nodes, but not all nodes are validators.
              </li>
            </ul>
            <br />
            Validators:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Validators are special nodes that participate in the consensus
                process to create and approve new blocks.
              </li>
              <li>
                They stake (lock up) tokens as collateral, which can be slashed
                if they misbehave.
              </li>
              <li>
                Validators earn rewards for their role in securing the network
                and processing transactions.
              </li>
              <li>
                They have voting power proportional to their stake and
                participate in on-chain governance.
              </li>
              <li>
                Only the node operators with over 100 Hippocrat nodes can
                volunteer to become validators. New validators will be decided
                by votes from existing validators.
              </li>
            </ul>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            TOKEN ALLOCATION
            <br />
            FOR HIPPO PROTOCOL <span>PARTICIPATION</span>
          </div>
          <div className={styles.overviewText}>
            To incentivize network participation and ensure long-term
            sustainability, we propose the following token allocation strategy
            for the mainnet:
            <br />
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                80% of inflation will be allocated as rewards for network
                participants.
              </li>
              <li style={{ marginLeft: 20 }}>
                90% of these rewards will go to nodes.
              </li>
              <li style={{ marginLeft: 20 }}>
                10% of these rewards will go to validators.
              </li>
              <li>
                10% will be allocated to ecosystem growth and liquidity provider
                rewards
              </li>
              <li>
                5% will be allocated as the dev grant for dev teams contributing
                to the tech stacks of Hippo Protocol mainnet. The grants will be
                proposed and voted by the community.
              </li>
              <li>
                5% will be allocated to airdrop and marketing for the community
                expansion. Airdrop plans will also be proposed and voted by the
                community.
              </li>
            </ul>
            This allocation strategy aims to:
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>Encourage widespread participation in running nodes.</li>
              <li>
                Ensure adequate compensation for validators' critical role in
                network security.
              </li>
              <li>Promote network decentralization and resilience.</li>
            </ul>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            BENEFITS TO THE <span>COMMUNITY</span>
          </div>
          <div className={styles.overviewText}>
            <ul style={{ paddingLeft: 20, margin: 0 }}>
              <li>
                Enhanced Security: A robust node and validator network will
                strengthen the security and reliability of the Hippocrat
                blockchain.
              </li>
              <li>
                Trustless Data Validation: As healthcare providers and medical
                institutions are participating as nodes, the data onboarded to
                the protocol will be validated by both the data source and the
                data owner without a need for 3rd party verification.
              </li>
              <li>
                Decentralization: Encouraging widespread participation in
                running nodes will promote a more decentralized network.
              </li>
              <li>
                Long-term Sustainability: The reward structure ensures ongoing
                incentives for network maintenance and security.
              </li>
              <li>
                Increased Utility: The expanded ecosystem will provide more use
                cases and applications for Hippocrat tokens, potentially
                increasing their value and demand.
              </li>
            </ul>
            <br />
            We believe these steps are crucial for Hippocrat's evolution and
            will create substantial value for all stakeholders. In the following
            sections, we'll delve into the details of each aspect of this
            proposal, including the precise number of tokens to be minted.
            <br />
            <br />
            Your input is vital to this process. We encourage all token holders
            to review the proposal carefully and participate in the vote.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            MINTING <span>STRATEGY</span>
          </div>
          <div className={styles.overviewText}>
            All of the existing HPO will be exchanged 1:1 with the HP token,
            before any inflation happens. Following is the inflation plan of HP
            every year after the mainnet transition.
            <br />
          </div>
          <div className={styles.overviewText}>
            <div className={styles.mintingTable}>
              <div className={styles.mintingHeader}>
                <div className={styles.mintingHeaderTitle}></div>
                <div className={styles.mintingHeaderTitle}>Year1</div>
                <div className={styles.mintingHeaderTitle}>Year2</div>
                <div className={styles.mintingHeaderTitle}>Year3</div>
                <div className={styles.mintingHeaderTitle}>Year4</div>
                <div className={styles.mintingHeaderTitle}>Year5</div>
                <div className={styles.mintingHeaderTitle}>Year6</div>
                <div className={styles.mintingHeaderTitle}>Every 2 yrs</div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>Inflation</div>
                <div className={styles.mintingBodyTitle}>25%</div>
                <div className={styles.mintingBodyTitle}>20%</div>
                <div className={styles.mintingBodyTitle}>8.33%</div>
                <div className={styles.mintingBodyTitle}>7.69%</div>
                <div className={styles.mintingBodyTitle}>3.57%</div>
                <div className={styles.mintingBodyTitle}>3.45%</div>
                <div className={styles.mintingBodyTitle}>Half-life</div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>
                  Increased
                  <br />
                  Supply
                </div>
                <div className={styles.mintingBodyTitle}>271,183,568</div>
                <div className={styles.mintingBodyTitle}>271,183,568</div>
                <div className={styles.mintingBodyTitle}>135,591,784</div>
                <div className={styles.mintingBodyTitle}>135,591,784</div>
                <div className={styles.mintingBodyTitle}>67,795,892</div>
                <div className={styles.mintingBodyTitle}>67,795,892</div>
                <div className={styles.mintingBodyTitle}>
                  Inflation
                  <br />
                  decreases
                  <br />
                  by half
                </div>
              </div>
              <div className={styles.mintingBody}>
                <div className={styles.mintingBodyTitle}>Total supply</div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  1,355,917,841
                </div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  1,627,101,410
                </div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  1,762,693,194
                </div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  1,898,284,978
                </div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  1,966,080,870
                </div>
                <div
                  className={styles.mintingBodyTitle}
                  style={{ fontSize: 10 }}
                >
                  2,033,876,762
                </div>
                <div className={styles.mintingBodyTitle}>-</div>
              </div>
            </div>
          </div>
          <div className={styles.overviewText}>
            First 4 years accelerated inflation, decelerated inflation for the
            years after, and deceleration continues as the half-life comes every
            two years. Token is designed to be deflationary.{' '}
            <strong>
              The initial years have accelerated inflation to encourage the
              participation of ecosystem partners and community members.
              However, once we onboard millions of users through our partners as
              planned, this will generate enough on-chain transactions and
              activities to offset the inflation, making the overall token
              supply deflationary through increased utility. As more people
              utilize the protocol and more gas fee is used, the net token
              circulating supply will decrease with time. This will make the
              token actually deflationary because more tokens will come back to
              the ecosystem vault than minted.
            </strong>{' '}
            The inflation will begin after the launch of Hippo Protocol mainnet.
            Migration of existing HPO to HP will be open for one year after the
            mainnet launch.
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            REFERENCE MINTING STRATEGIES:
            <br />
            REBRANDED <span>PROJECTS</span>
          </div>
          <div className={styles.overviewText}>
            Our transition is not without precedent in the Web 3.0 world.
            Several successful projects have undergone similar transformations,
            demonstrating the potential for growth and expanded impact through
            rebranding and pivoting to Layer 1 solutions. Here are two notable
            examples:
            <br />
            <br />
            1. Vanar Chain (formerly Terra Virtua) : Listed in Binance &
            Coinbase
            <br />
            <div style={{ marginLeft: 15 }}>
              <a
                href="https://coinmarketcap.com/currencies/vanar/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/vanar/
              </a>{' '}
            </div>
            <div style={{ marginLeft: 25 }}>
              <li>100% Increase in Total Supply (Total Supply became 2X)</li>
              <li>1:1 Swap between TVK and VANRY</li>
              <li>New Supply was allocated as</li>
              <ol type="i" style={{ paddingLeft: 50, margin: 0 }}>
                <li>83% to validator rewards</li>
                <li>13% for development rewards</li>
                <li>4% for airdrops and other community incentives</li>
              </ol>
            </div>
            <br />
            <br />
            2. Self Chain (formerly Frontier Wallet): Listed in Binance
            <br />
            <div style={{ marginLeft: 15 }}>
              <a
                href="https://coinmarketcap.com/currencies/self-chain/"
                target="_blank"
                style={{ color: 'white' }}
              >
                https://coinmarketcap.com/currencies/self-chain/
              </a>{' '}
            </div>
            <div style={{ marginLeft: 25 }}>
              <li>300% Increase in Total Supply (Total Supply became 4X)</li>
              <li>1:1 Swap between FRONT and SLF</li>
              <li>Allocation for SLF was</li>
              <ol type="i" style={{ paddingLeft: 50, margin: 0 }}>
                <li>25% airdrop to existing holder</li>
                <li>10% to equity investors</li>
                <li>28% to validator nodes / growth sale</li>
                <li>19% to ecosystem</li>
                <li>10% to foundation nodes</li>
                <li>8% to team</li>
              </ol>
            </div>
          </div>
          <div className={styles.overviewTitle} style={{ marginTop: 200 }}>
            FREQUENTLY ASKED <span>QUESTIONS</span>
          </div>
          <div className={styles.overviewText}>
            To provide further clarity on our proposal, we've addressed some
            common questions below:
            <br />
            <br />
            Q: Will the node distribution amounts be made public?
            <br />
            A: To respect the privacy of our partners, we won't disclose
            specific distribution amounts. However, we will publish a list of
            investors to maintain transparency.
            <br />
            <br />
            Q: Doesn't having a fixed number of nodes limit decentralization?
            <br />
            A: We understand this concern. While we're minting a total of 50,000
            nodes, we're starting with an initial distribution of 30,000. The
            remaining 20,000 will be distributed as needed, based on future
            community voting. This approach allows us to grow and adapt to
            network needs while maintaining a high degree of decentralization.
            <br />
            <br />
            Q: Who will act as validators?
            <br />
            A: During the initial migration phase, the foundation and key
            investors will operate as validators to ensure a smooth transition.
            After this phase, validators will be selected through a community
            voting process from among node operators holding more than 100
            nodes. This transition ensures both initial stability and long-term
            community governance.
            <br />
            <br />
            Q: How will the token migration process work?
            <br />
            A: We're providing two main methods for a smooth migration:
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              <li>Automatic distribution through participating exchanges</li>
              <li>
                A proprietary bridge that will operate for one year, allowing
                for direct transfers
              </li>
            </ol>
            <br />
            Q: How will the initial 30,000 nodes be distributed? <br />
            A: The initial nodes will be distributed using our node dashboard.
            Whitelisted partners can operate them directly.
            <br />
            <br />
            We hope these answers provide additional clarity. If you have any
            further questions, please don't hesitate to ask in our community
            channels.
          </div>
        </div>
      </div>
      <MMainnetNodePage />
    </section>
  );
};

export default MainnetNodePage;
