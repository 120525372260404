import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  AboutUsPage,
  CommunityVotePage,
  MainnetNodePage,
  BlogPage,
  DaoPage,
  ErrorPage,
  FaqPage,
  HomePage,
  HpoTokenPage,
  LearnWeb3Page,
  LearnHealthcareDataPage,
  Learnhl7Page,
  Learnp2pPage,
  MediaKitPage,
  NewsPage,
  P2pPage,
  ProtocolPage,
  RoadMapPage,
  SdkPage,
  HippoPage,
  WhitePaperPage,
} from '../../pages';
import { DefaultLayout } from '../../layouts';

export const HippoRouterProvider = () => {
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      errorElement: <ErrorPage />,
      Component: DefaultLayout,
      children: [
        {
          index: true,
          path: '/',
          Component: HomePage,
        },
        {
          path: '/aboutUs',
          Component: AboutUsPage,
        },
        {
          path: '/hippocratCommunityVote',
          Component: CommunityVotePage,
        },
        {
          path: '/mainnetNode',
          Component: MainnetNodePage,
        },
        {
          path: '/blog',
          Component: BlogPage,
        },

        {
          path: '/dao',
          Component: DaoPage,
        },
        {
          path: '/faq',
          Component: FaqPage,
        },
        {
          path: '/hpoToken',
          Component: HpoTokenPage,
        },
        {
          path: '/learn/web3',
          Component: LearnWeb3Page,
        },
        {
          path: '/learn/healthcareData',
          Component: LearnHealthcareDataPage,
        },
        {
          path: '/learn/hl7',
          Component: Learnhl7Page,
        },
        {
          path: '/learn/p2pProtocol',
          Component: Learnp2pPage,
        },
        {
          path: '/pressKit',
          Component: MediaKitPage,
        },
        {
          path: '/news',
          Component: NewsPage,
        },
        {
          path: '/p2p',
          Component: P2pPage,
        },
        {
          path: '/protocol',
          Component: ProtocolPage,
        },
        {
          path: '/roadMap',
          Component: RoadMapPage,
        },
        {
          path: '/sdk',
          Component: SdkPage,
        },
        {
          path: '/hippodoc',
          Component: HippoPage,
        },
        {
          path: '/whitepaper',
          Component: WhitePaperPage,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<></>} />;
};
